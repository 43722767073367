const getSchemaDataBySlug = (slug) => {
    switch (slug) {
        case '/service/event-planung-tirol':
            return {
                name:"Eventplanung",
                image: "https://www.eventation.at/static/0b999a270c86e4e9111cc11a9292effa/47498/gira-konferenz-sommer-gala.jpg",
                description:"Professionelle Eventplanung in Tirol, inklusive Location-Scouting, Transport- und Unterkunftsplanung sowie personalisierte Konzepte für Teamevents, Incentives und Outdoor-Veranstaltungen."
            };
        case '/service/event-kulinarik-tirol':
            return {
                name:"Kulinarik für Events",
                image: "https://www.eventation.at/static/ed95a7a05b9a37064ed20ea3861e7002/47498/eventgastronomie-kulinarik-traditionell.jpg",
                description:"Professionelle kulinarische Konzepte für Veranstaltungen in Tirol. Darunter exklusive Themenabende, Outdoor-Küchen, Galadinner und regionale Spezialitäten."
            }
        case '/service/event-technik-tirol':
            return {
                name:"Eventtechnik",
                image:"https://www.eventation.at/static/8e3c997dd83ed43352fd826a1ff760c9/47498/winterolympiade_quad.jpg",
                description:"Umfassende Eventtechnik in Tirol. Einschließlich Bühnenbau, Licht- und Sounddesign, sowie kundenspezifische Location-Bauten und stilvolles Inventar."
            }
        case '/service/event-ambiente-tirol':
            return {
                name:"Event-Ambiente",
                image:"https://www.eventation.at/static/8dccdc71195d9295b694440228344e34/47498/gira-konferenz-sommer-outdoor2.jpg",
                description:"Unvergessliches Event-Ambiente in Tirol für Innen- und Außenbereiche. Mit kreativen Dekorationen, stilvoller Inneneinrichtung und stimmungsvollem Lichtdesign schaffen wir zauberhafte Atmosphären für Teamevents, Produktpräsentationen oder Automotive Events."
            }
        case '/service/event-entertainment-tirol':
            return {
                name:"Event-Entertainment",
                image:"https://www.eventation.at/static/99070056085cbb667a9199c8bc081df9/47498/seat-ateca-presslaunch-huettenabend-entertainment.jpg",
                description:"Professionelle Event-Entertainment in Tirol, das jede Veranstaltung unvergesslich macht. Von Livemusik und professioneller Moderation bis hin zu spektakulären Shows. Wir sorgen für begeisternde Unterhaltung bei Teamevents, Incentives, Produktpräsentationen und Automotive Events."
            }
        case '/service/event-media-tirol':
            return {
                name:"Event-Media",
                image:"https://www.eventation.at/static/5f913a77196a1607918dab5944f475ae/47498/ath-ebiketour-sommer.jpg",
                description:"Professionelle Event-Media-Lösungen in Tirol, die Ihre Veranstaltung professionell dokumentieren und in sozialen Medien präsentieren. Von Foto- und Video-Dokumentation über interaktive Fotoboxen bis hin zu Social Media Hubs: Wir fangen die besten Momente Ihres Events ein!"
            }
        case '/angebot/sommer-event-tirol':
            return {
                name:"Sommer-Event",
                image:"https://www.eventation.at/static/824f5f2c5caab5a4239479268bd78c2c/4fe8c/polytech-eventation-team2.jpg",
                description:"Kundenspezifische Sommer-Events in Tirol, die unvergessliche Erlebnisse in der Natur schaffen. Von abenteuerlichen E-Bike-Touren und Hochgebirgstouren bis hin zu exklusiven Locations wie der Bergiselschanze. Ideal für Teamevents, Incentives und Teambuilding-Aktivitäten, um Ihre Gäste zu begeistern und bleibende Erinnerungen zu schaffen."
            }
        case '/angebot/winter-event-tirol':
            return {
                name:"Winter-Event",
                image:"https://www.eventation.at/static/a49a14070b53bd8e7f80afe04e6f3c01/47498/mercedes-outdoor-winterevent.jpg",
                description:"Einzigartige Winter-Events in Tirol, die magische Momente im Schnee schaffen. Ob Teamevents, Incentives oder Teambuilding, wir gestalten unvergessliche Erlebnisse in der beeindruckenden Winterlandschaft, von Winterolympiaden über Trappertage bis hin zu exklusiven Events in außergewöhnlichen Locations wie der Bergiselschanze oder den Swarovski Kristallwelten."
            }
        case '/angebot/abend-event-tirol':   
            return{
                name:"Abend-Event",
                image:"https://www.eventation.at/static/fa3a223f24f7d9aa565adadfb0b69e5d/47498/gira-grossveranstaltung-sommer.jpg",
                description:"Unvergessliche Abend-Events in Tirol, die Genuss, Unterhaltung und unvergessliche Momente vereinen. Ob stilvolles Galadinner, rustikaler Hüttenabend oder exklusives Event in atemberaubenden Locations wie den Swarovski Kristallwelten oder der Kaiserlichen Hofburg Innsbruck, wir sorgen dafür, dass Ihre Gäste einen außergewöhnlichen Abend erleben. Mit kulinarischen Highlights und kreativen Programmpunkten gestalten wir einzigartige Events, die in Erinnerung bleiben."
            }
        case '/angebot/automotive-event-tirol':
            return {
                name:"Automotive-Event",
                image:"https://www.eventation.at/static/350f2a4a143e1cbf1eba89f594a22963/47498/nightsky_car_pitztal_composite-1_retouch-1.jpg",
                description:"Moderne Automotive-Events die einzigartige Erlebnisse in den Tiroler Bergen bieten. Ideal für Fahrzeugpräsentationen, Offroad-Trainings und exklusive Testfahrten."
            }
        case '/angebot/grossveranstaltungen-event-tirol':
            return{
                name:"Großveranstaltungen",
                image:"https://www.eventation.at/static/dc181388d9d5f6f61261fa91637e961b/47498/grossveranstaltung-gira-alpach.jpg",
                description:"Großveranstaltungen in Tirol mit über 300 Teilnehmern. Von glamourösen Galadinners bis hin zu spektakulären Showabenden. Wir bieten umfassende Planung, perfekte Location-Auswahl und professionelle Umsetzung für unvergessliche Erlebnisse."
            }                                     
        default:
            return null; // Return null if no schema is defined for the slug
    }
};

module.exports = { getSchemaDataBySlug }
