import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import MetaContext from '../contexts/meta';
import LocaleContext from '../contexts/locale';
import { getSchemaDataBySlug } from '../helper/getSchemasFromUrl';

// import styles from './meta-tags.module.scss';

const Metatags = () => {
    const {
        currentLocale: locale
    } = useContext(LocaleContext);
    const location = useLocation();
    const {
        titles,
        description
    } = useContext(MetaContext);
    const title = titles[0];
    const pathSegments = location.pathname.split('/').filter(Boolean);

    const breadcrumbs = pathSegments.map((segment, index) => {
        const breadcrumbPath = `/${pathSegments.slice(0, index + 1).join('/')}/`;
        const breadcrumbName = segment.replace(/-/g, ' '); // Replace hyphens with spaces
    
        return {
          name: breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1),
          path: breadcrumbPath,
        };
      });

    let slug = location.pathname;
    if(slug==='/en/') slug = '/en'
    const canonicalUrl = `https://www.eventation.at${slug}`;

    const noIndexSlugs = [
        '/agb', 
        '/impressum',
        '/datenschutz'
    ];
    const shouldNoIndex = noIndexSlugs.includes(slug);
    const schemaData = getSchemaDataBySlug(slug);
    return (
        <Helmet>
            <html lang={locale} />
            <title>{title}</title>
            <meta name='robots' content={shouldNoIndex ? 'noindex, nofollow' : 'index, follow'} />
            <meta name='description' content={description} />
            <meta name='og:locale' content={locale} />
            <meta name='og:type' content='website' />
            <meta name='og:title' content={title} />
            <meta name='og:description' content={description} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
            <link rel='manifest' href='/site.webmanifest' />
            <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ff6316' />
            <meta name="msapplication-TileColor" content="#191919" />
            <meta name="theme-color" content="#ffffff" />
            <link rel="canonical" href={canonicalUrl} />
            {schemaData && (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Service",
                        "name":schemaData.name,
                        "image":schemaData.image,
                        "description":schemaData.description,
                        "brand":{
                            "@type":"brand",
                            "name": "eventation GmbH"
                        }
                    })}
                </script>
            )}
            <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": `${'https://eventation.at'}/`,
            },
            ...breadcrumbs.map((breadcrumb, index) => ({
              "@type": "ListItem",
              "position": index + 2,
              "name": breadcrumb.name,
              "item": `${'https:/eventation.at'}${breadcrumb.path}`,
            })),
          ],
        })}
      </script>
        </Helmet>
    );
};

export default Metatags;