import React,{useEffect} from 'react';
import PropTypes from 'prop-types';

// import styles from './utils.module.scss';

const parseMarkdown = (content, props) => {
	// Inline styles as a string
	const buttonStyles = 'padding: 0; background-color: transparent; color: #ad272f; border: none; cursor: pointer;';
	// Simple replacement for a custom button shortcode [[button slug="slug" label="Click Me"]]
	return content.replace(
	  /\[\[button slug="(.*?)" label="(.*?)"\]\]/g,
	  (_, slug, label) =>
		`<button class="clickable-button" style="${buttonStyles}" data-slug="${slug}">${label}</button>`
	);
  };
  
  

  export const Markdown = (props) => {
	const { content, setHighlightSlug } = props;
  
	props = {
	  ...props,
	  ...{
		content: undefined,
	  },
	};
  
	delete props.content;
  
	return <HTML content={parseMarkdown(content, props)} setHighlightSlug={setHighlightSlug} />;
  };
  
  Markdown.propTypes = {
	content: PropTypes.string.isRequired,
	setHighlightSlug: PropTypes.func.isRequired, // Add prop type for the function
  };

export const HTML = (props) => {
	const html = {
	  __html: props.content,
	};
  
	useEffect(() => {
	  // Find all buttons with the class "clickable-button"
	  const buttons = document.querySelectorAll('.clickable-button');
  
	  // Define the click handler
	  const handleClick = (event) => {
		const slug = event.target.getAttribute('data-slug');
		if (slug && props.setHighlightSlug) {
		  // Call the setHighlightSlug function passed as prop
		  props.setHighlightSlug(slug);
		}
	  };
  
	  // Attach event listeners to each button
	  buttons.forEach((button) => {
		button.addEventListener('click', handleClick);
	  });
  
	  // Cleanup event listeners on unmount
	  return () => {
		buttons.forEach((button) => {
		  button.removeEventListener('click', handleClick);
		});
	  };
	}, [props.content, props.setHighlightSlug]);
  
	props = {
	  ...props,
	  ...{
		content: undefined,
	  },
	};
  
	delete props.content;
  
	return <div {...props} dangerouslySetInnerHTML={html} />;
  };
  
  HTML.propTypes = {
	content: PropTypes.node.isRequired,
	setHighlightSlug: PropTypes.func, // Add prop type for the function to update the state
  };

export const Conditional = ({ condition, wrapper, children, fallbackWrapper }) => {
	return condition
		? wrapper(children)
		: (fallbackWrapper && fallbackWrapper(children)) || children;
};

Conditional.propTypes = {
	condition: PropTypes.any,
	fallbackWrapper: PropTypes.func,
	wrapper: PropTypes.func.isRequired,
	children: PropTypes.element.isRequired
};
